<template>
<div class="container" v-loading='loading'>
    <div class="create-title">海报生成</div>
    <div class="create-container">
        <div class="form-item">
            <div class="key">海报图片</div>
            <div class="val upload">
                <el-upload class="poster-uploader" :show-file-list="false" drag
                    :action="FILE_UPLOAD_END_POINT" accept="image/png, image/jpeg, image/jpg"
                    :before-upload='beforeUpload'
                    :on-change='handleUploadChange'
                    :on-success="handleUploadSuccess">
                    <div v-if="imageUrl" class="display">
                        <img :src="imageUrl"/>
                        <div class="change">更换</div>
                    </div>
                    <div v-else class="empty">
                        <i class="el-icon-plus"/>
                        <span>选择文件</span>
                    </div>
                </el-upload>
                <div class="tip">
                    <p>文件格式：.jpg、.png或.jpeg</p>
                    <p>图片大小：不超过2M</p>
                    <p>图片尺寸：推荐图片长宽比为9：16</p>
                </div>
            </div>
        </div>
        <div class="form-item">
            <div class="key">效果预览</div>
            <div class="val canvas">
                <div class="canvas-container">
                    <img class="background" :src="imageUrl" />
                    <canvas id="qrcode-canvas" ref="qrcode-canvas" class="qrcode" :width="canvasWidth" :height="canvasHeight"></canvas>
                </div>
            </div>
        </div>
        <div class="form-item">
            <div class="key"></div>
            <div class="val">
                <el-button icon="iconfont icon-download" type="primary" style="margin-right: 25px;" @click="handleDownload"> 下载海报</el-button>
                <el-button icon='iconfont icon-upload' style="color: #1D517E;borderColor: #1D517E;" @click="handleUploadPoster"> 保存海报</el-button>
            </div>
        </div>

    </div>
    <canvas id="poster-canvas" :width="canvasWidth * 2" :height="canvasHeight * 2" style="display: none;"></canvas>
    <qriously id="qrcode" ref="qrcode" :value='qrCodeLink' style="display: none;"></qriously>
</div>
</template>

<script>
import Core from '@/core';
import axios from 'axios';

function dataURLtoFile(dataurl, filename) {//将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
    let arr = dataurl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}

export default {
    components: {},
    data() {
        return {
            FILE_UPLOAD_END_POINT: Core.Const.NET.FILE_UPLOAD_END_POINT,
            IMG_URL_PREFIX: Core.Const.NET.FILE_URL_PREFIX2,

            loading: true,
            activity_id: '',
            channel_id: '',
            detail: {},

            imageUrl: '',
            bgImage: '', // Image

            qrCodeImgSrc: '', // base
            qrCodeImg: '', // Image
            qrCodeLink: '',

            canvasWidth: 450,
            canvasHeight: 800,
            qrCodeCanvas: null,
            ctx: null,
            imgX: 0, // 图片在画布中渲染的起点x坐标
            imgY: 0,
            imgScale: 1, // 图片的缩放大小
        }
    },
    watch: {},
    computed: {},
    created() {
        this.activity_id = this.$route.query.id || '';
        this.channel_id = this.$route.query.channel || '';
        this.qrCodeLink = `http://gda.school.rebuildsoft.com/#/before?id=${this.activity_id}&channel=${this.channel_id}`;
    },
    mounted() {
        this.qrCodeCanvas = this.$refs['qrcode-canvas'];
        this.ctx = this.qrCodeCanvas.getContext('2d');
        this.getActivityDetail();
        this.canvasEventsInit();
    },
    methods: {
        getActivityDetail() {
            this.loading = true
            Core.operationApi.Evaluation.detail(
                this.activity_id
            ).then(res => {
                console.log('getActivityDetail', res)
                this.detail = res.detail
                if (res.detail.playbill_config) {
                    let playbill_config = JSON.parse(res.detail.playbill_config)
                    console.log('playbill_config:', playbill_config)
                    this.imageUrl = playbill_config.background,
                    this.imgScale = playbill_config.imgScale
                    this.imgX = playbill_config.imgX
                    this.imgY = playbill_config.imgY
                }
                this.handleQrCodeCreate();
                this.loading = false
            })
        },

         // 上传背景
        beforeUpload(file) {  // 上传背景 校验图片
            const isJPG = ['image/jpeg','image/jpg', 'image/png'].includes(file.type);
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isJPG) {
                this.$message.warning('请上传，JPG、PNG或JPEG格式的图片');
            }
            if (!isLt2M) {
                this.$message.warning('上传图像大小不要超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        handleUploadChange(file, fileList) {  // 上传背景 状态提示
            console.log('handleUploadChange file:', file , 'fileList', fileList)
            switch (file.status) {
                case 'success':
                    this.$message.success('上传成功');
                    break;
                case 'error':
                    this.$message.error('上传失败');
                    break;
            }
        },
        handleUploadSuccess(res, file) { // 上传背景 成功
            console.log('handleUploadSuccess res:', res, 'file', file)
            this.imageUrl = res.data.name ? this.IMG_URL_PREFIX + res.data.name : ''
            console.log('this.imageUrl:', this.imageUrl)
        },

        // 二维码 行为
        handleQrCodeCreate() {  // 生成二维码图像
            let qrcode = document.getElementById("qrcode");
            let qrcodeImgDom = qrcode.children[0]

            this.qrCodeImgSrc = qrcodeImgDom.toDataURL('image/png').replace('image/png', 'image/octet-stream')
            let img = new Image();   // 创建img元素
            img.src = this.qrCodeImgSrc; // 设置图片源地址
            this.qrCodeImg = img
            setTimeout(() => {this.drawQrCode()}, 100)
        },
        canvasEventsInit() { // 拖拽 & 缩放
            let _this = this;
            let canvas = this.qrCodeCanvas;
            canvas.onmousedown = function (event) {

                let imgx = _this.imgX;
                let imgy = _this.imgY;
                let pos = { //坐标转换，将窗口坐标转换成canvas的坐标
                    x: event.clientX,
                    y: event.clientY
                };

                canvas.onmousemove = function (evt) {  //移动
                    canvas.style.cursor = 'move';
                    let x = (evt.clientX - pos.x) + imgx;
                    let y = (evt.clientY - pos.y) + imgy;
                    _this.imgX = x;
                    _this.imgY = y;
                    _this.drawQrCode();  //重新绘制图片
                };
                canvas.onmouseup = function () {
                    canvas.onmousemove = null;
                    canvas.onmouseup = null;
                    canvas.style.cursor = 'default';
                };
            };

            canvas.onmousewheel = canvas.onwheel = function (event) {    //滚轮放大缩小
                //获取当前鼠标的滚动情况
                let wheelDelta = event.wheelDelta ? event.wheelDelta : (event.deltaY * (-40));

                let imgScale = _this.imgScale
                if (wheelDelta > 0) {
                    imgScale += 0.1;
                } else {
                    if (imgScale > 0.5) {
                        imgScale -= 0.1;
                    }
                }
                _this.imgScale = parseFloat(parseFloat(imgScale).toFixed(2))
                _this.drawQrCode();   //重新绘制图片
                event.preventDefault && event.preventDefault();
                return false;
            };
        },
        drawQrCode() { // 绘制
            let _this = this;
            this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
            this.ctx.drawImage(
                _this.qrCodeImg, //规定要使用的图片
                _this.imgX,
                _this.imgY, //在画布上放置图像的 x 、y坐标位置。
                100 * _this.imgScale,
                100 * _this.imgScale //要使用的图像的宽度、高度
            );
        },

        // 下载海报
        handleDownload() {
            if (!this.imageUrl) {
                return this.$message.warning('请上传海报底图')
            }
            let canvas = document.getElementById("poster-canvas");
            let ctx = canvas.getContext("2d");

            let img = new Image();   // 创建img元素
            // img.crossOrigin= 'anonymous'
            img.crossOrigin= 'anonymous'
            img.setAttribute('crossOrigin', 'anonymous')
            img.src = this.imageUrl; // 设置图片源地址
            img.onload = () => {
                ctx.drawImage(img, 0, 0, this.canvasWidth * 2, this.canvasHeight * 2);
                ctx.drawImage(this.qrCodeImg, this.imgX * 2, this.imgY * 2, 2 * 100 * this.imgScale, 2 * 100 * this.imgScale);

                let imgURL = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
                let dlLink = document.createElement('a');
                dlLink.download = '海报.png';
                dlLink.href = imgURL;
                dlLink.dataset.downloadurl = ["image/png", dlLink.download, dlLink.href].join(':');

                dlLink.click();
            }
        },

        // 保存海报
        handleUploadPoster() {
            if (!this.imageUrl) {
                return this.$message.warning('请上传海报底图')
            }
            this.loading = true

            let canvas = document.getElementById("poster-canvas");
            let ctx = canvas.getContext("2d");

            let img = new Image();   // 创建img元素
            img.src = this.imageUrl; // 设置图片源地址
            img.crossOrigin= 'anonymous'
            img.setAttribute('crossOrigin', 'anonymous')
            img.onload = () => {
                ctx.drawImage(img, 0, 0, this.canvasWidth * 2, this.canvasHeight * 2);
                ctx.drawImage(this.qrCodeImg, this.imgX * 2, this.imgY * 2, 2 * 100 * this.imgScale, 2 * 100 * this.imgScale);
                let base64Data = canvas.toDataURL('image/png');

                let file = dataURLtoFile( base64Data, 'playbill_url.png')
                let formData = new FormData();
                formData.append("file", file);

                let headers = { 'Content-Type': 'multipart/form-data' }
                axios.post(
                    this.FILE_UPLOAD_END_POINT,
                    formData,
                    {headers}
                ).then(res => {
                    console.log('handleUploadPoster res', res);
                    let name = res.data.data.name
                    this.handleSavePoster(name)
                }).catch(err => {
                    console.log('handleUploadPoster err:', err)
                    // this.$message.info('上传完整图片失败')
                });
            }
        },
        handleSavePoster(name) {
            let playbill_config = {
                background: this.imageUrl,
                imgScale: parseFloat(parseFloat(this.imgScale).toFixed(1)),
                imgX: this.imgX,
                imgY: this.imgY,
            }
            let playbill_url = this.IMG_URL_PREFIX + name;
            Core.operationApi.Evaluation.save(
                this.activity_id,
                this.detail.name,
                this.detail.count,
                this.detail.form_field,
                playbill_url,
                JSON.stringify(playbill_config),
                this.detail.experience_courses,
                1,
            ).then(res => {
                console.log('handleSavePoster res:', res)
                this.$message.success('上传成功')
                this.loading = false
            }).catch(err => {
                console.log('handleSavePoster err:', err)
                this.$message.info('上传活动信息失败')
            });
        },
    },
}
</script>
<style lang="scss" scoped>
.container {
    padding: 0 30px 40px;
    .create-title {
        height: 62px;
        line-height: 62px;
        font-size: 20px;
        font-weight: 500;
        color: #323538;
    }
    .create-container {
        width: 100%;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #E1E7EC;
        overflow: hidden;
        box-sizing: border-box;
        padding: 36px 60px;
        .form-item {
            display: flex;
            margin-bottom: 40px;
            .key {
                color: #323538;
                width: 90px;
            }
            .val {
                width: calc(100% - 90px);
            }
            .upload {
                display: flex;
            }
            .tip {
                margin-left: 20px;

                font-size: 14px;
                font-weight: 400;
                color: #999999;
                p {
                    margin-bottom: 8px;
                }
            }
            .canvas {
                display: flex;
                .canvas-container + .canvas-container {
                    margin-left: 40px;
                }
            }
        }
    }
    .poster-uploader {
        .empty {
            @include flex(column, center, center);
            width: 100%;
            height: 100%;
            color: #666666;
            background: #FAFBFC;
            i.el-icon-plus {
                font-size: 20px;
            }
            > span {
                font-size: 12px;
            }
        }
        .display {
            width: 100%;
            height: 100%;
            position: relative;
            z-index: 1;
            .change {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                width: 100%;
                height: 28px;
                line-height: 28px;
                color: #fff;
                z-index: 2;
                background-color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .canvas-container {
        width: 450px;
        height: 800px;
        background-color: #F1F1F1;
        position: relative;
        .background {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
        }
        .qrcode {
            position: relative;
            z-index: 2;
        }
    }
}
</style>

<style lang="scss">
.poster-uploader {
    .el-upload {
        .el-upload-dragger {
            width: 81px;
            height: 144px;
        }
    }
}
</style>
